import React from 'react'
import { PrivacyPolicy } from '../components/pages/privacyPolicy'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

const PrivacyPolicyPage = (props: any) => {
  const siteMetadata = useSiteMetadata()

  return <PrivacyPolicy {...props} en={false} siteMetadata={siteMetadata} />
}

export default PrivacyPolicyPage
